import cloneDeep from 'lodash.clonedeep';
import { action, computed, makeObservable, observable } from 'mobx';
import { createContext } from 'react';

import { daDataServices } from '../../services/daDataServices';
import { formSendServices } from '../../services/formSendServices';
import { prices } from '../pricesPage/pricesPage.models';
import {
  defaultTableRow,
  flowmeters,
  otherEquipment,
  generalId,
  deviceDiameterId,
} from './components/devicesForm/deviceForm.models';
import { ALERT_COLORS, STEPS_CHECK } from './createFormPage.models';

class Store {
  constructor() {
    this.isOpenCalendar = false;
    this.recaptchaToken = '';
    this.isFormPageFetchList = false;
    this.isTableFilled = false;
    this.alert = {
      isAlertShow: false,
      alertTypeColor: ALERT_COLORS.RED,
      alertMsg: '',
    };

    this.createFormPageStep = STEPS_CHECK[0];
    this.isActiveDeviceTableAddBtn = false;
    this.organizationList = [];
    this.applicationFormData = {
      clientData: {
        orgName: '',
        orgInn: '',
        name: '',
        phone: '',
        email: '',
        msg: '',
        visitDate: '',
      },
      devicesTableData: [cloneDeep(defaultTableRow)],
    };

    makeObservable(this, {
      isOpenCalendar: observable,
      getIsOpenCalendar: computed,
      setIsOpenCalendar: action,
      setVisitDate: action,
      getVisitDate: computed,

      recaptchaToken: observable,
      isFormPageFetchList: observable,
      organizationList: observable,
      applicationFormData: observable,
      getApplicationFormData: computed,

      isTableFilled: observable,
      alert: observable,
      createFormPageStep: observable,
      isActiveDeviceTableAddBtn: observable,

      setIsActiveDeviceTableAddBtn: action,
      getIsActiveDeviceTableAddBtn: computed,

      setRecaptchaToken: action,
      getRecaptchaToken: computed,

      setIsFormPageFetchList: action,
      getIsFormPageFetchList: computed,

      startAlertFetching: action,

      setAlertMsg: action,
      getIsAlertShow: computed,
      setIsAlertShow: action,
      getAlertMsg: computed,
      setAlertTypeColor: action,
      getAlertTypeColor: computed,

      setDevicesTableData: action,
      setFormPageMsg: action,
      getFormPageMsg: computed,

      getOrganization: action,
      clearOrganizationList: action,
      setClientOrganization: action,
      getClientOrganization: computed,
      setClientPhone: action,
      getClientPhone: computed,
      setClientSurname: action,
      getClientSurname: computed,
      setClientEmail: action,
      getClientEmail: computed,
      checkIsTableFilled: action,
      sendForm: action,
      setIsTableFilled: action,
      getIsTableFilled: computed,
      setCreateFormPageStep: action,
      getCreateFormPageStep: computed,

      setCalculateCostColumn: action,
    });
  }

  setCalculateCostColumn() {
    const table = this.getApplicationFormData.devicesTableData;
    if (this.getIsTableFilled) {
      table.map(row => {
        if (row.diameter !== 'нет' && flowmeters.some(devTpe => devTpe === row.deviceType)) {
          const devicePriceField = prices.flowmeter.find(device => device.diameter === Number(row.diameter))[
            row.deviceType
          ];
          row.cost = devicePriceField * row.deviceQuantity;
          return;
        }
        if (otherEquipment.some(devTpe => devTpe === row.deviceType)) {
          const devicePriceField = prices.other.find(device => device.name === row.deviceType).price;
          row.cost = devicePriceField * row.deviceQuantity;
          return;
        }
        row.cost = 0;
      });
      return;
    }
    table.map(row => (row.cost = 0));
  }

  setFormPageMsg(msg) {
    this.applicationFormData.clientData.msg = msg;
  }

  get getFormPageMsg() {
    return this.applicationFormData.clientData.msg;
  }

  get getIsOpenCalendar() {
    return this.isOpenCalendar;
  }

  setIsOpenCalendar(bool) {
    this.isOpenCalendar = bool;
  }

  setVisitDate(date) {
    this.applicationFormData.clientData.visitDate = date;
  }

  get getVisitDate() {
    return this.applicationFormData.clientData.visitDate;
  }

  startAlertFetching() {
    this.setAlertMsg('Отправка заявки...');
    this.setAlertTypeColor(ALERT_COLORS.YELLOW);
    this.setIsAlertShow(true);
  }

  get getIsFormPageFetchList() {
    return this.isFormPageFetchList;
  }

  setIsFormPageFetchList(bool) {
    this.isFormPageFetchList = bool;
  }

  setRecaptchaToken(newToken) {
    this.recaptchaToken = newToken;
  }

  get getRecaptchaToken() {
    return this.recaptchaToken;
  }

  setIsActiveDeviceTableAddBtn(bool) {
    this.isActiveDeviceTableAddBtn = bool;
  }

  get getIsActiveDeviceTableAddBtn() {
    return this.isActiveDeviceTableAddBtn;
  }

  setAlertTypeColor(color) {
    this.alert.alertTypeColor = color;
  }

  get getAlertTypeColor() {
    return this.alert.alertTypeColor;
  }

  setCreateFormPageStep(step) {
    this.createFormPageStep = STEPS_CHECK[step];
  }

  get getCreateFormPageStep() {
    return this.createFormPageStep;
  }

  setAlertMsg(msg) {
    this.alert.alertMsg = msg;
  }

  get getIsAlertShow() {
    return this.alert.isAlertShow;
  }

  get getAlertMsg() {
    return this.alert.alertMsg;
  }

  setIsAlertShow(bool) {
    this.alert.isAlertShow = bool;
  }

  addGeneralCodeInDevicesTableData() {
    const { devicesTableData } = this.getApplicationFormData;
    devicesTableData.map(device => {
      device.diameterCode = deviceDiameterId[device.diameter];
      for (let key in generalId) {
        if (generalId[key].length === 1) {
          if (generalId[key].some(item => item === device.deviceType)) {
            device.deviceGeneralCode = Number(key);
            return;
          }
        } else {
          if (
            generalId[key].some(item => item === device.deviceType) &&
            generalId[key].some(item => item === Number(device.diameter))
          ) {
            device.deviceGeneralCode = Number(key);
            return;
          }
        }
      }
    });
  }

  sendForm() {
    this.addGeneralCodeInDevicesTableData();
    const { clientData, devicesTableData } = this.getApplicationFormData;
    const table = ['table', JSON.stringify(devicesTableData)];
    const recaptchaToken = ['recaptcha_token', this.getRecaptchaToken];
    const parcel = [...Object.entries(clientData), table, recaptchaToken];
    formSendServices
      .sendFormSer(parcel)
      .then(res => {
        if (res.type === 'success') {
          this.setAlertTypeColor(ALERT_COLORS.GREEN);
        } else {
          this.setAlertTypeColor(ALERT_COLORS.RED);
          this.setCreateFormPageStep(0);
        }

        this.setIsAlertShow(true);
        this.setAlertMsg(res.msg);
      })
      .catch(e => {
        this.setAlertTypeColor(ALERT_COLORS.RED);
        this.setAlertMsg('Произошла ошибка, данные не отправлены, попробуйте позже.');
        this.setIsAlertShow(true);
        throw new Error(e.message);
      });
  }

  setDevicesTableData(tableData) {
    this.applicationFormData.devicesTableData = tableData;
  }

  checkIsTableFilled() {
    const table = this.getApplicationFormData.devicesTableData;
    if (
      table.length !== 0 &&
      table[table.length - 1].deviceQuantity > 0 &&
      table[table.length - 1].serialNumber.trim() &&
      table[table.length - 1].deviceModel.trim() !== ''
    ) {
      return true;
    }

    return false;
  }

  get getIsTableFilled() {
    if (this.checkIsTableFilled()) {
      this.setIsTableFilled(true);
    } else {
      this.setIsTableFilled(false);
      this.setAlertMsg('Обязательные поля в таблице не заполнены');
      this.setAlertTypeColor(ALERT_COLORS.RED);
    }
    return this.isTableFilled;
  }

  setIsTableFilled(bool) {
    this.isTableFilled = bool;
  }

  clearOrganizationList() {
    this.organizationList = [];
  }

  get getApplicationFormData() {
    return this.applicationFormData;
  }

  setClientOrganization([orgName = '', orgInn = '']) {
    this.applicationFormData.clientData.orgName = orgName;
    this.applicationFormData.clientData.orgInn = orgInn;
  }

  getOrganization({ userQuery }) {
    this.setIsFormPageFetchList(true);
    this.clearOrganizationList();
    daDataServices
      .getOrganizationDescription({ userQuery })
      .then(res => {
        const organizationsData = res.suggestions;
        const organizations = organizationsData.map(organization => {
          return {
            name: organization.value,
            inn: organization.data.inn,
          };
        });
        this.organizationList = organizations;
      })
      .then(() => this.setIsFormPageFetchList(false))
      .catch(e => {
        throw new Error(e.message);
      });
  }

  get getClientOrganization() {
    if (!this.applicationFormData.clientData.orgInn) {
      return this.applicationFormData.clientData.orgName;
    }
    return `${this.applicationFormData.clientData.orgName} (${this.applicationFormData.clientData.orgInn})`;
  }

  setClientPhone(value) {
    this.applicationFormData.clientData.phone = value;
  }

  get getClientPhone() {
    return this.applicationFormData.clientData.phone;
  }

  setClientSurname(value) {
    this.applicationFormData.clientData.name = value;
  }

  get getClientSurname() {
    return this.applicationFormData.clientData.name;
  }

  setClientEmail(value) {
    this.applicationFormData.clientData.email = value;
  }

  get getClientEmail() {
    return this.applicationFormData.clientData.email;
  }
}

export const CreateFormPageStoreContext = createContext(new Store());
