import React from 'react';
import ReactDOM from 'react-dom';

import './style/style.scss';

import { App } from './app/app';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render(
  <GoogleReCaptchaProvider
    useRecaptchaNet
    reCaptchaKey={process.env.RECAPTCHA_SITE_KEY_WWW}
    scriptProps={{ async: true, defer: true, appendTo: 'head' }}
  >
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById('root'),
);
