export const deviceTypes = [
  '',
  'Тепловычислитель',
  'Расходомер электромагнитный',
  'Расходомер ультразвуковой',
  'Расходомер крыльчатый',
  'Манометр технический',
  'Термометр сопротивления',
  'Преобразователь давления',
  'Термоманометр',
];

export const flowmeters = [deviceTypes[2], deviceTypes[3], deviceTypes[4]];
export const otherEquipment = [deviceTypes[1], deviceTypes[5], deviceTypes[6], deviceTypes[7], deviceTypes[8]];

export const defaultDeviceDiameter = ['нет', 10, 15, 20, 25, 32, 40, 50, 65, 80, 100, 125, 150];

export const deviceDiameterId = {
  [defaultDeviceDiameter[0]]: 0,
  [defaultDeviceDiameter[1]]: 13,
  [defaultDeviceDiameter[2]]: 1,
  [defaultDeviceDiameter[3]]: 2,
  [defaultDeviceDiameter[4]]: 3,
  [defaultDeviceDiameter[5]]: 4,
  [defaultDeviceDiameter[6]]: 5,
  [defaultDeviceDiameter[7]]: 6,
  [defaultDeviceDiameter[8]]: 7,
  [defaultDeviceDiameter[9]]: 8,
  [defaultDeviceDiameter[10]]: 9,
  [defaultDeviceDiameter[11]]: 10,
  [defaultDeviceDiameter[12]]: 11,
};

export const defaultDeviceQuantity = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const defaultTableRow = {
  id: 1,
  deviceType: deviceTypes[0],
  deviceMaker: '',
  deviceModel: '',
  serialNumber: '',
  diameter: defaultDeviceDiameter[0],
  deviceQuantity: defaultDeviceQuantity[0],
  cost: '',
  deviceGeneralCode: '',
  diameterCode: '',
};

export const generalId = {
  1: ['Термометр сопротивления'],
  2: ['Преобразователь давления'],
  3: ['Манометр технический'],
  4: ['Тепловычислитель'],
  5: ['Расходомер электромагнитный', 10, 15, 20, 25, 32, 40],
  6: ['Расходомер электромагнитный', 50],
  7: ['Расходомер электромагнитный', 65],
  8: ['Расходомер электромагнитный', 80],
  9: ['Расходомер ультразвуковой', 10, 15, 20, 25, 32, 40],
  10: ['Расходомер ультразвуковой', 50],
  11: ['Расходомер ультразвуковой', 65],
  12: ['Расходомер ультразвуковой', 80],
  13: ['Расходомер крыльчатый', 10, 15, 20, 25, 32, 40],
  14: ['Расходомер крыльчатый', 50],
  15: ['Расходомер крыльчатый', 65],
  18: ['Расходомер крыльчатый', 80],
  19: ['Расходомер электромагнитный', 150],
  20: ['Расходомер электромагнитный', 125],
  21: ['Расходомер электромагнитный', 100],
  22: ['Термоманометр'],
};
