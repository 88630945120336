import React from 'react';

import './detailsPage.scss';

export const DetailsPage = () => {
  return (
    <section className="detailsPage pt-5 pb-5">
      <div className="detailsPage-wrapper pb-5 container">
        <h2 className="my-h2">Реквизиты</h2>
        <div className="detailsPage-Text fs-15">
          <div className="pb-5">
            <h3 className="pb-4">Реквизиты организации:</h3>
            <div className="pl-4">
              <p>
                <b>Наименование организации:</b> ООО &quot;ТТ-Энерго&quot;
              </p>
              <p>
                <b>ОГРН:</b> 1181832003099
              </p>
              <p>
                <b>ИНН:</b> 1840077410
              </p>
              <p>
                <b>КПП:</b> 184001001
              </p>
              <p>
                <b>Директор:</b> Никонов Николай Александрович
              </p>
              <p>
                <b>Юридический адрес:</b> 426053, Удмуртская Республика, г. Ижевск, ул. Салютовская, 5, пом. 1
              </p>
              <p>
                <b>Фактический адрес:</b> 426053, Удмуртская Республика, г. Ижевск, ул. Салютовская, 5, пом. 1
              </p>
              <p>
                <b>Телефон:</b> +7 (3412) 912426
              </p>
              <p>
                <b>Электронный адрес:</b> info@tt-en.ru
              </p>
              <p>
                <b>Сайт:</b> http://tt-en.ru
              </p>
            </div>
          </div>
          <div>
            <h3 className="pb-4">Банковские реквизиты:</h3>
            <div className="pl-4">
              <p>
                <b>Р/С:</b> 40702810568000006112 в Удмуртском отделении №8618 ПАО Сбербанк г. ИЖЕВСК
              </p>
              <p>
                <b>К/С:</b> 30101810400000000601
              </p>
              <p>
                <b>БИК:</b> 049401601
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
