export const CHECK = {
  ORGANIZATION_CHECK: 'organization',
  DEVICE_LIST_CHECK: 'devicesList',
};

export const STEPS_CHECK = [CHECK.ORGANIZATION_CHECK, CHECK.DEVICE_LIST_CHECK];

export const FORM_ERROR = {
  ORGANIZATION_ERROR: 'organization error',
  SURNAME_ERROR: 'name error',
  PHONE_ERROR: 'phone error',
  EMAIL_ERROR: 'email error',
};

export const TABLE_ERROR = {
  TYPE_ERROR: 'type error',
  MAKER_ERROR: 'maker error',
  MODEL_ERROR: 'model error',
  SERNUMBER_ERROR: 'serial number error',
  DIAMETER_ERROR: 'diameter error',
  QUANTITY_ERROR: 'quantity error',
};

export const ALERT_COLORS = {
  GREEN: 'success',
  RED: 'danger',
  YELLOW: 'warning',
};
