export const prices = {
  flowmeter: [
    {
      diameter: 10,
      'Расходомер электромагнитный': 2980,
      'Расходомер ультразвуковой': 3600,
      'Расходомер крыльчатый': 1430,
    },
    {
      diameter: 15,
      'Расходомер электромагнитный': 2980,
      'Расходомер ультразвуковой': 3600,
      'Расходомер крыльчатый': 1430,
    },
    {
      diameter: 20,
      'Расходомер электромагнитный': 2980,
      'Расходомер ультразвуковой': 3600,
      'Расходомер крыльчатый': 1430,
    },
    {
      diameter: 25,
      'Расходомер электромагнитный': 2980,
      'Расходомер ультразвуковой': 3600,
      'Расходомер крыльчатый': 1430,
    },
    {
      diameter: 32,
      'Расходомер электромагнитный': 2980,
      'Расходомер ультразвуковой': 3600,
      'Расходомер крыльчатый': 1430,
    },
    {
      diameter: 40,
      'Расходомер электромагнитный': 2980,
      'Расходомер ультразвуковой': 3600,
      'Расходомер крыльчатый': 1430,
    },
    {
      diameter: 50,
      'Расходомер электромагнитный': 3267,
      'Расходомер ультразвуковой': 3600,
      'Расходомер крыльчатый': 1650,
    },
    {
      diameter: 65,
      'Расходомер электромагнитный': 4200,
      'Расходомер ультразвуковой': 3600,
      'Расходомер крыльчатый': 1760,
    },
    {
      diameter: 80,
      'Расходомер электромагнитный': 4200,
      'Расходомер ультразвуковой': 4200,
      'Расходомер крыльчатый': 1760,
    },
    {
      diameter: 100,
      'Расходомер электромагнитный': 4800,
      'Расходомер ультразвуковой': '',
      'Расходомер крыльчатый': '',
    },
    {
      diameter: 125,
      'Расходомер электромагнитный': 4800,
      'Расходомер ультразвуковой': '',
      'Расходомер крыльчатый': '',
    },
    {
      diameter: 150,
      'Расходомер электромагнитный': 5000,
      'Расходомер ультразвуковой': '',
      'Расходомер крыльчатый': '',
    },
  ],

  other: [
    {
      name: 'Тепловычислитель',
      nameCode: 4,
      price: '3575',
    },
    {
      name: 'Преобразователь давления',
      nameCode: 2,
      price: '2260',
    },
    {
      name: 'Термометр сопротивления',
      nameCode: 1,
      price: '730',
    },
    {
      name: 'Термоманометр',
      nameCode: 22,
      price: '300',
    },
    {
      name: 'Манометр технический',
      nameCode: 3,
      price: '150',
    },
  ],
};
