import React from 'react';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';

import { Header } from './components/header/header';
import { Footer } from './components/footer/footer';
import { HomePage } from './components/homePage/homePage';
import { ContactsPage } from './components/contactsPage/contactsPage';
import { DetailsPage } from './components/detailsPage/detailsPage';
import { PricesPage } from './components/pricesPage/pricesPage';
import { CreateFormPage } from './components/createFormPage/createFormPage';

import { ROUTES } from './routing/routes';

import './app.scss';

export const App = () => {
  return (
    <BrowserRouter>
      <div className="app">
        <Header />
        <Switch>
          <Route exact path={ROUTES.HOME}>
            <HomePage />
          </Route>
          <Route exact path={ROUTES.CONTACTS}>
            <ContactsPage />
          </Route>
          <Route exact path={ROUTES.DETAILS}>
            <DetailsPage />
          </Route>
          <Route exact path={ROUTES.PRICES}>
            <PricesPage />
          </Route>
          <Route exact path={ROUTES.CREATEFORMPAGE}>
            <CreateFormPage />
          </Route>
          <Redirect from="*" to={ROUTES.HOME} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
};
