// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/logo-tt.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".footer{padding-top:15px;padding-bottom:15px;background-color:#0f203c;color:rgba(255,255,255,.9)}.footer .logo-wrapper{width:fit-content;margin:0 auto}.footer .logoImgFooter{margin:15px;background-size:contain;background-repeat:no-repeat;width:148px;height:115px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.footer .my-link{font-style:normal;font-weight:normal;letter-spacing:1.9px;font-size:1.1rem;color:inherit}.footer .my-link:hover{color:#ffffffce}.footer .phone-link{white-space:nowrap}.footer .footer-highlight-btn{background-color:#f6e942;border-radius:150px;padding:3px auto 3px auto;white-space:nowrap;color:#000}.footer .footer-highlight-btn:hover{box-shadow:0 0px 5px 0px #fff,0 0px 5px 0px #fff;color:#000}", ""]);
// Exports
module.exports = exports;
