import { computed, makeObservable, observable } from 'mobx';
import { createContext } from 'react';

import { prices } from './pricesPage.models';

class Store {
  constructor() {
    this.tables = {
      flowmeter: prices.flowmeter,
      other: prices.other,
    };

    makeObservable(this, {
      tables: observable,
      getTables: computed,
    });
  }

  get getTables() {
    return this.tables;
  }
}

export const pricesPageStoreContext = createContext(new Store());
