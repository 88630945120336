import './organizationForm.scss';

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Form, InputGroup, Button, FormControl, ListGroup, Spinner } from 'react-bootstrap';
import { useCallback } from 'react';

import { CreateFormPageStoreContext } from '../../createFormPageStore';

export const OrganizationForm = observer(() => {
  const formPageStore = useContext(CreateFormPageStoreContext);
  const [isFindBlockOpen, setIsFindBlockOpen] = useState(false);

  const openFindBlock = useCallback(() => {
    setIsFindBlockOpen(!isFindBlockOpen);
    formPageStore.setClientOrganization();
  }, [formPageStore, isFindBlockOpen]);

  const handleOrganizationInput = useCallback(
    e => {
      formPageStore.setClientOrganization([e.target.value, e.target.dataset.organization_inn]);
      const query = formPageStore.getClientOrganization;
      formPageStore.getOrganization({ userQuery: query });
    },
    [formPageStore],
  );

  const handleOrganizationCheck = useCallback(
    e => {
      formPageStore.setClientOrganization([e.target.dataset.organization_name, e.target.dataset.organization_inn]);
      setIsFindBlockOpen(false);
      setTimeout(() => setIsFindBlockOpen(false), 1000);
    },
    [formPageStore],
  );

  const closeFindBlock = useCallback(() => {
    setIsFindBlockOpen(false);
  }, []);

  const handleOrganizationInputOnFocus = useCallback(() => {
    formPageStore.clearOrganizationList();
    setIsFindBlockOpen(true);
  }, [formPageStore]);

  const handleOrganizationInputOnBlur = useCallback(() => {
    formPageStore.setClientOrganization([]);
    setIsFindBlockOpen(false);

    setTimeout(() => formPageStore.clearOrganizationList(), 1000);
  }, [formPageStore]);

  const handlePhoneInput = useCallback(
    e => {
      formPageStore.setClientPhone(e.target.value);
    },
    [formPageStore],
  );

  const handleSurnameInput = useCallback(
    e => {
      formPageStore.setClientSurname(e.target.value);
    },
    [formPageStore],
  );

  const handleEmailInput = useCallback(
    e => {
      formPageStore.setClientEmail(e.target.value);
    },
    [formPageStore],
  );

  const handleVisitDateChange = useCallback(
    e => {
      formPageStore.setVisitDate(e.target.value);
    },
    [formPageStore],
  );

  const handleOtherInformationChange = useCallback(
    e => {
      formPageStore.setFormPageMsg(e.target.value);
    },
    [formPageStore],
  );

  return (
    <div className="col-12 col-md-10 col-lg-6 col-xl-5">
      <Form.Group className="mb-0" controlId="formOrganization">
        <Form.Label>* Выберите организацию:</Form.Label>
        <InputGroup className="">
          <FormControl
            className="my-input"
            required={true}
            type="text"
            onChange={e => handleOrganizationInput(e)}
            value={formPageStore.getClientOrganization}
            placeholder={!isFindBlockOpen ? 'Ваша организация...' : 'Введите ИНН или название'}
            onFocus={() => handleOrganizationInputOnFocus()}
            onBlur={() => handleOrganizationInputOnBlur()}
            autoComplete="off"
          />
          {isFindBlockOpen ? (
            <InputGroup.Append>
              <Button
                className="fs-13 organizationForm_closeBtn"
                variant="outline-secondary"
                onClick={() => openFindBlock()}
              >
                {formPageStore.getIsFormPageFetchList ? <Spinner animation="border" aria-hidden="true" /> : 'X'}
              </Button>
            </InputGroup.Append>
          ) : null}
        </InputGroup>
      </Form.Group>
      <ListGroup
        style={
          !isFindBlockOpen ? { height: '0px' } : { height: `${(formPageStore.organizationList.length + 1) * 39}px` }
        }
        className="formOrganization-list mb-3"
        defaultActiveKey="link1"
      >
        {formPageStore.organizationList.length !== 0 ? (
          formPageStore.organizationList.map(org => {
            return (
              <ListGroup.Item
                variant="info"
                onClick={e => handleOrganizationCheck(e)}
                data-organization_name={org.name}
                data-organization_inn={org.inn}
                key={`${org.name} (${org.inn})`}
              >{`${org.name} (${org.inn})`}</ListGroup.Item>
            );
          })
        ) : (
          <ListGroup.Item variant="info" onClick={() => closeFindBlock()}>
            {'...'}
          </ListGroup.Item>
        )}
      </ListGroup>
      <Form.Group className="pb-3" controlId="formUserName">
        <Form.Label>* Имя:</Form.Label>
        <Form.Control
          className="my-input"
          required={true}
          type="text"
          onChange={e => handleSurnameInput(e)}
          value={formPageStore.getClientSurname}
          placeholder="ФИО"
        />
      </Form.Group>
      <Form.Group className="pb-3" controlId="formPhone">
        <Form.Label>* Телефон:</Form.Label>
        <Form.Control
          className="my-input"
          required={true}
          type="text"
          onChange={e => handlePhoneInput(e)}
          value={formPageStore.getClientPhone}
          placeholder="8 (XXX) XXX-XX-XX"
        />
      </Form.Group>
      <Form.Group className="pb-3" controlId="formEmail">
        <Form.Label>* E-mail:</Form.Label>
        <Form.Control
          className="my-input"
          required={true}
          type="email"
          onChange={e => handleEmailInput(e)}
          value={formPageStore.getClientEmail}
          placeholder="E-mail"
        />
      </Form.Group>
      <Form.Group className="pb-3" controlId="formDate">
        <Form.Label>Дата посещения:</Form.Label>
        <Form.Control
          className="my-input"
          onChange={e => handleVisitDateChange(e)}
          value={formPageStore.getVisitDate}
          required={false}
          type="date"
        />
      </Form.Group>
      <Form.Group className="pb-3" controlId="formMsg">
        <Form.Label>Дополнительная информация:</Form.Label>
        <FormControl
          className="my-input"
          as="textarea"
          onChange={e => handleOtherInformationChange(e)}
          value={formPageStore.getFormPageMsg}
          aria-label="otherInformation"
        />
      </Form.Group>
    </div>
  );
});
