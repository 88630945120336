import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../routing/routes';

import './homePage.scss';

export const HomePage = () => {
  return (
    <>
      <section className="home-block1">
        <div className="row">
          <div className="block1-contacts col-12 col-md-5 col-lg-5 col-xl-4">
            <p>+7 (3412) 912426</p>
            <p>info@tt-en.ru</p>
            <p>г. Ижевск, ул. Салютовская, 5а</p>
          </div>
        </div>
        <div className="row">
          <div className="bgimage"></div>
        </div>
        <div className="row">
          <div className="block1-specialty ml-auto col-12 col-md-5 col-lg-5">
            <h1 className="specialty-title">ТТ-Энерго специализируется на поверке:</h1>
            <ul className="specialty-list pb-5">
              <li className="specialty-list-row">Расходомеры жидкости</li>
              <li className="specialty-list-row">Вычислители тепловой энергии</li>
              <li className="specialty-list-row">Термопреобразователи сопротивления</li>
              <li className="specialty-list-row">Датчики давления</li>
              <li className="specialty-list-row">Манометры</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="home-block2">
        <div className="row">
          <div className="block2-sendForm col-12 col-md-6 col-xl-4">
            <div className="sendForm-wrapper justify-content-center justify-content-md-end mt-5 mb-5">
              <div className="sendForm">
                <h1 className="sendForm-title">ТТ-Энерго</h1>
                <p className="sendForm-text">Поверка, техническое обслуживание и ремонт в одном месте!</p>
                <Link
                  className="nav-link my-link footer-highlight-btn mt-1"
                  data-path={ROUTES.CREATEFORMPAGE}
                  to={ROUTES.CREATEFORMPAGE}
                >
                  <button className="btn sendForm-btn" type="button">
                    Оставить заявку
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="block2-imgWrapper col-12 col-md-6 col-xl-8">
            <div className="block2-image" />
          </div>
        </div>
        <div className="block2-cards">
          <div className="container-lg">
            <div className="row justify-content-center">
              <div className="col-12 col-md-4 col-sxl-3">
                <div className="card mx-auto card-why px-4">
                  <div className="icon ico-why mt-5 mb-4"></div>
                  <h2 className="card-title mb-3">Зачем поверять приборы?</h2>
                  <p className="card-text why-text mb-5">
                    Примерный срок службы приборов учета 12 лет, со временем происходит износ компонентов, поэтому
                    приборам необходима периодическая поверка и калибровка
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 col-sxl-3">
                <div className="card mx-auto card-process px-4">
                  <div className="icon ico-process mt-5 mb-4"></div>
                  <h2 className="card-title mb-3">Процедура поверки приборов</h2>
                  <p className="card-text process-text mb-5">
                    Перед поверкой счетчики чаще всего приходится калибровать, т.к. в период эксплуатации их погрешность
                    увеличивается. После калибровки счетчики поверяются на проливной установке
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 col-sxl-3">
                <div className="card mx-auto card-cost px-4">
                  <div className="icon ico-cost mt-5 mb-4"></div>
                  <h2 className="card-title">Стоимость поверки приборов</h2>
                  <p className="card-text process-text">
                    Наша компания предлагает доступные цены на поверку приборов учета. Стоимость поверки напрямую
                    зависит от типа (диаметра) прибора. Конкретную стоимость можно посмотреть в прайсе
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-block3">
        <div className="block3-wrapper">
          <div className="row">
            <div className="col-12 col-md-9">
              <div className="block3-sendForm-image"></div>
              <div className="sendForm-bgimage"></div>
            </div>
          </div>
          <div className="row sendForm-row mx-0">
            <div className="block3-sendForm-wrapper col-12 col-md-6 col-xl-4">
              <div className="sendForm-wrapper d-flex justify-content-center justify-content-md-end">
                <div className="sendForm">
                  <h1 className="sendForm-title">ТТ-Энерго</h1>
                  <p className="sendForm-text">Доверьте Ваши приборы профессионалам.</p>
                  <Link
                    className="nav-link my-link footer-highlight-btn mt-1"
                    data-path={ROUTES.CREATEFORMPAGE}
                    to={ROUTES.CREATEFORMPAGE}
                  >
                    <button className="btn sendForm-btn" type="button">
                      Оставить заявку
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="advantages-cards">
            <div className="row no-gutters justify-content-center">
              <div className="col d-none d-md-block">
                <div className="adv-startCard"></div>
              </div>
              <div className="col-11 col-lg-10 col-xl-9">
                <div className="row no-gutters justify-content-center">
                  <div className="col-12 col-sm-6 col-md-3 col-xl-3 col-sxl mb-4">
                    <div className="adv-card">
                      <div className="mx-auto icon ico-time mb-4"></div>
                      <p className="advantages-text mb-0">Поверка приборов в течении 2х недель!</p>
                      <div className="advantage-card-border-right d-none d-sm-block"></div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 col-xl-3 col-sxl mb-4">
                    <div className="adv-card">
                      <div className="mx-auto icon ico-call mb-4"></div>
                      <p className="advantages-text mb-0">Консультация со специалистом</p>
                      <div className="advantage-card-border-right d-none d-md-block"></div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 col-xl-3 col-sxl mb-4">
                    <div className="adv-card">
                      <div className="mx-auto icon ico-repair mb-4"></div>
                      <p className="advantages-text mb-0">Возможность технического обслуживания или ремонта</p>
                      <div className="advantage-card-border-right d-none d-sm-block"></div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 col-xl-3 col-sxl mb-4">
                    <div className="adv-card">
                      <div className="mx-auto icon ico-cost mb-4"></div>
                      <p className="advantages-text mb-0">Если прибор не проходит поверку оплата не взимается</p>
                    </div>
                  </div>
                  <div className="col-3 d-none d-sxl-block  mb-4">
                    <div className="adv-card adv-card--last"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-block4">
        <div className="service-wrapper">
          <div className="service">
            <div className="row justify-content-center">
              <div className="col-9 text-md-left text-sm-center">
                <h1 className="service-title">Наши услуги</h1>
              </div>
            </div>
            <div className="row service-cards justify-content-center">
              <div className="col-2 d-none d-sxl-block"></div>
              <div className="col-11 col-sm-7 col-md-6 col-lg-11 col-xl-10">
                <div className="row">
                  <div className="col-12 col-lg-4 col-sxl-3">
                    <div className="service-card">
                      <div className="mounting-img mx-auto mb-5">
                        <button type="button" className="btn cardBtn mx-auto">
                          Монтажные работы
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 col-sxl-3">
                    <div className="service-card">
                      <div className="verify-img mx-auto mb-5">
                        <button type="button" className="btn cardBtn mx-auto">
                          Поверка приборов учета
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 col-sxl-3">
                    <div className="service-card">
                      <div className="electricMounting-img mx-auto mb-5">
                        <button type="button" className="btn cardBtn mx-auto">
                          Электромонтажные работы
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sxl-3 d-none d-sxl-block">
                    <div className="service-card card-shadow">
                      <div className="shadow-img mx-auto mb-5"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
