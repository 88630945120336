import './pricesPage.scss';

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useContext } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { pricesPageStoreContext } from './pricesPageStore';

export const PricesPage = observer(() => {
  const pricesPageStore = useContext(pricesPageStoreContext);

  return (
    <section className="pricePage pt-5 pb-5">
      <div className="container">
        <h2 className="my-h2">Прайс-лист</h2>
        <BootstrapTable
          className="price__table fs-15 mb-5"
          hover
          tableBodyClass="price__table-light"
          data={pricesPageStore.getTables.flowmeter}
          tableHeaderClass="price__table-gray"
        >
          <TableHeaderColumn className="price__table-lightGray" row="0" colSpan="4" dataAlign="center">
            Преобразователи расхода
          </TableHeaderColumn>
          <TableHeaderColumn row="1" colSpan="1" rowSpan="2" dataField="diameter" isKey dataAlign="center" width="80">
            Ду
          </TableHeaderColumn>
          <TableHeaderColumn row="1" colSpan="3" dataAlign="center">
            Стоимось услуги (без НДС), &#8381; *
          </TableHeaderColumn>

          <TableHeaderColumn row="2" colSpan="1" dataField="Расходомер электромагнитный" dataAlign="center" width="230">
            Электромагнитные и прочие
          </TableHeaderColumn>
          <TableHeaderColumn row="2" colSpan="1" dataField="Расходомер ультразвуковой" dataAlign="center" width="180">
            Ультразвуковые
          </TableHeaderColumn>
          <TableHeaderColumn row="2" colSpan="1" dataField="Расходомер крыльчатый" dataAlign="center" width="180">
            Крыльчатые
          </TableHeaderColumn>
        </BootstrapTable>

        <BootstrapTable
          className="price__table fs-15 mb-5"
          hover
          data={pricesPageStore.getTables.other}
          options={{ sortIndicator: true }}
          tableHeaderClass="price__table-gray"
          tableBodyClass="price__table-light"
        >
          <TableHeaderColumn className="price__table-lightGray" row="0" colSpan="2" dataAlign="center">
            Прочее оборудование
          </TableHeaderColumn>
          <TableHeaderColumn row="1" dataField="name" isKey dataAlign="center" width="300">
            Наименование прибора
          </TableHeaderColumn>
          <TableHeaderColumn row="1" dataField="price" dataAlign="center" width="180">
            Стоимось услуги (без НДС), &#8381; *
          </TableHeaderColumn>
        </BootstrapTable>
        <p className="fs-15 ls-1">* - Окончательную цену узнавайте у специалиста по номеру +7(3412)912426</p>
      </div>
    </section>
  );
});
