import './deviceForm.scss';

import React, { useCallback, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import cloneDeep from 'lodash.clonedeep';
import { observer } from 'mobx-react-lite';

import { defaultDeviceDiameter, defaultTableRow, defaultDeviceQuantity, deviceTypes } from './deviceForm.models';
import { CreateFormPageStoreContext } from '../../createFormPageStore';

export const DevicesForm = observer(() => {
  const formPageStore = useContext(CreateFormPageStoreContext);

  const addRow = useCallback(() => {
    formPageStore.setIsAlertShow(false);
    const newRow = Object.assign({}, defaultTableRow);
    const table = formPageStore.getApplicationFormData.devicesTableData;
    newRow.id = table.length === 0 ? 1 : table[table.length - 1].id + 1;
    formPageStore.setDevicesTableData([...formPageStore.getApplicationFormData.devicesTableData, newRow]);
    formPageStore.setIsActiveDeviceTableAddBtn(false);
  }, [formPageStore]);

  const onDeleteRow = useCallback(
    rowKeys => {
      const newTable = formPageStore.getApplicationFormData.devicesTableData.filter(row => {
        if (rowKeys.some(checkId => row.id === checkId)) {
          return false;
        }

        return true;
      });

      newTable.map((row, rowIndex) => (row.id = rowIndex + 1));
      formPageStore.setDevicesTableData(cloneDeep(newTable));
      formPageStore.setIsActiveDeviceTableAddBtn(true);
    },
    [formPageStore],
  );

  const isLastRowFull = useCallback(() => {
    formPageStore.setCalculateCostColumn();
    if (formPageStore.getIsTableFilled) {
      formPageStore.setIsActiveDeviceTableAddBtn(true);
      return;
    }

    formPageStore.setIsActiveDeviceTableAddBtn(false);
  }, [formPageStore]);

  return (
    <div className="col">
      <div className="devicesList pb-3">
        <BootstrapTable
          className="devicesList__table"
          options={{ onDeleteRow: onDeleteRow }}
          data={formPageStore.getApplicationFormData.devicesTableData}
          deleteRow={true}
          cellEdit={{
            mode: 'click',
            blurToSave: true,
            afterSaveCell: isLastRowFull,
          }}
          selectRow={{
            mode: 'checkbox',
            bgColor: 'pink',
          }}
        >
          <TableHeaderColumn dataField="id" dataAlign="center" width="60" isKey={true}>
            №
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="deviceType"
            width="250"
            editable={{ type: 'select', options: { values: deviceTypes } }}
          >
            Тип прибора *
          </TableHeaderColumn>
          <TableHeaderColumn dataField="deviceMaker" width="200">
            Изготовитель
          </TableHeaderColumn>
          <TableHeaderColumn dataField="deviceModel" width="150">
            Модель *
          </TableHeaderColumn>
          <TableHeaderColumn dataField="serialNumber" width="200">
            Номера приборов *
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="diameter"
            dataAlign="center"
            width="80"
            editable={{
              type: 'select',
              options: {
                values: defaultDeviceDiameter,
              },
            }}
          >
            Диаметр
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="deviceQuantity"
            dataAlign="center"
            width="80"
            editable={{
              type: 'select',
              options: {
                values: defaultDeviceQuantity,
              },
            }}
          >
            Кол-во *
          </TableHeaderColumn>
          <TableHeaderColumn dataField="cost" editable={false} dataAlign="center" width="80">
            Цена, &#8381;
          </TableHeaderColumn>
        </BootstrapTable>
        <Button
          className="form__btn devicesList__addButton"
          disabled={!formPageStore.getIsActiveDeviceTableAddBtn}
          onClick={() => addRow()}
          variant="primary"
        >
          Добавить
        </Button>
      </div>
    </div>
  );
});
