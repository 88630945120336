import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../routing/routes';

import './footer.scss';

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-wrapper container-lg">
        <div className="row justify-content-between">
          <div className="order-2 order-md-1">
            <div className="logo-wrapper">
              <Link className="logo" data-path={ROUTES.HOME} to={ROUTES.HOME}>
                <div className="logoImgFooter"></div>
              </Link>
            </div>
          </div>
          <div className="footer-menu mr-md-auto pl-md-5 order-1 pb-5">
            <Link className="nav-link my-link" data-path={ROUTES.HOME} to={ROUTES.HOME}>
              Главная
            </Link>
            <Link className="nav-link my-link" data-path={ROUTES.DETAILS} to={ROUTES.DETAILS}>
              Реквизиты
            </Link>
            <Link className="nav-link my-link" data-path={ROUTES.PRICES} to={ROUTES.PRICES}>
              Прайс
            </Link>
            <Link className="nav-link my-link" data-path={ROUTES.CONTACTS} to={ROUTES.CONTACTS}>
              Контакты
            </Link>
            <Link
              className="nav-link my-link footer-highlight-btn mt-1"
              data-path={ROUTES.CREATEFORMPAGE}
              to={ROUTES.CREATEFORMPAGE}
            >
              Оставить заявку
            </Link>
          </div>
          <div className="footer-contacts contacts order-3 ">
            <Link className="nav-link my-link contacts-link" data-path={ROUTES.CONTACTS} to={ROUTES.CONTACTS}>
              <i className="iconmoon icon-location" />
              г. Ижевск, ул. Салютовская, 5а
            </Link>
            <a className="nav-link my-link contacts-link" href="mailto:info@tt-en.ru">
              <i className="iconmoon icon-mail4" />
              info@tt-en.ru
            </a>
            <a className="nav-link my-link contacts-link phone-link" href="tel:+73412912426">
              <i className="iconmoon icon-phone" />
              +7 (3412) 912426
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
