import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

import { ROUTES } from '../../routing/routes';

import './header.scss';

export const Header = () => {
  return (
    <header className="header">
      <div className="header-wrapper">
        <Navbar className="myColor-light px-3prc" collapseOnSelect expand="lg">
          <Navbar.Brand>
            <Link className="logo" data-path={ROUTES.HOME} to={ROUTES.HOME}>
              <div className="logoImgHeader"></div>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto my-nav">
              <NavLink
                className="nav-link my-headerLink"
                activeClassName="active"
                isActive={match => match}
                data-path={ROUTES.HOME}
                to={ROUTES.HOME}
              >
                Главная
              </NavLink>
              <NavLink
                className="nav-link my-headerLink"
                activeClassName="active"
                isActive={match => match}
                data-path={ROUTES.DETAILS}
                to={ROUTES.DETAILS}
              >
                Реквизиты
              </NavLink>
              <NavLink
                className="nav-link my-headerLink"
                activeClassName="active"
                isActive={match => match}
                data-path={ROUTES.PRICES}
                to={ROUTES.PRICES}
              >
                Прайс
              </NavLink>
              <NavLink
                className="nav-link my-headerLink"
                activeClassName="active"
                isActive={match => match}
                data-path={ROUTES.CONTACTS}
                to={ROUTES.CONTACTS}
              >
                Контакты
              </NavLink>
              <NavLink
                className="nav-link my-headerLink"
                activeClassName="active"
                isActive={match => match}
                data-path={ROUTES.CREATEFORMPAGE}
                to={ROUTES.CREATEFORMPAGE}
              >
                <div className="header-highlight-btn">Оставить заявку</div>
              </NavLink>
            </Nav>
            <Nav className="header-signin">
              <Nav.Link href="#" className="mx-auto" disabled={true}>
                Войти
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};
