import { ENDPOINT } from './formSendServices.models';

class FormSendServices {
  constructor() {
    this.endpoint = ENDPOINT;
  }

  async sendFormSer(elements) {
    const formData = new FormData();
    elements.map(element => {
      formData.append(element[0], element[1]);
    });

    const response = await fetch(this.endpoint, {
      method: 'POST',
      body: formData,
    });

    const responseJson = await response.json();
    return responseJson;
  }
}

export const formSendServices = new FormSendServices();
