// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/logo-full.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".header-wrapper{box-shadow:0 -4px 10px 0px #000;color:#1d2240;font-size:1.1rem;font-weight:500;letter-spacing:1px;text-transform:uppercase;position:relative;z-index:100}.header-wrapper .myColor-light{background-color:#dae3ea}.header-wrapper .my-nav{justify-content:space-between}.header-wrapper .header-highlight-btn{background-color:#f6e942;border-radius:150px;padding:3px 30px 3px 30px;white-space:nowrap}.header-wrapper .header-highlight-btn:hover{box-shadow:0 0px 5px 0px #fff,0 0px 5px 0px #fff}.header-wrapper .logoImgHeader{width:173px;height:45px;margin:10px 0 10px 0;background-size:cover;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.header-wrapper .my-headerLink{color:#1d2240b9;align-self:center}.header-wrapper .my-headerLink .active{color:#1d2240 !important}@media(min-width: 1400px){.my-headerLink{padding-left:30px !important;padding-right:30px !important}}", ""]);
// Exports
module.exports = exports;
