import './createFormPage.styles.scss';

import React, { useCallback, useContext } from 'react';
import { OrganizationForm } from './components/organizationForm/organizationForm';
import { Alert, Button, Form } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { ALERT_COLORS, CHECK } from './createFormPage.models';
import { DevicesForm } from './components/devicesForm/devicesForm';
import { CreateFormPageStoreContext } from './createFormPageStore';

export const CreateFormPage = observer(() => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formPageStore = useContext(CreateFormPageStoreContext);

  const closeAlert = useCallback(() => {
    formPageStore.setIsAlertShow(false);
  }, [formPageStore]);

  const nextStepClick = useCallback(
    async event => {
      event.preventDefault();
      if (formPageStore.getCreateFormPageStep === CHECK.ORGANIZATION_CHECK) {
        formPageStore.setIsAlertShow(false);
        formPageStore.setCreateFormPageStep(1);
        return;
      }

      formPageStore.startAlertFetching();

      if (formPageStore.getIsTableFilled) {
        formPageStore.setIsAlertShow(true);
        if (!executeRecaptcha) {
          return;
        }

        const result = await executeRecaptcha('submit');
        formPageStore.setRecaptchaToken(result);
        formPageStore.sendForm();
      }
    },
    [formPageStore, executeRecaptcha],
  );

  const backStepClick = useCallback(() => {
    formPageStore.setIsAlertShow(false);
    formPageStore.setCreateFormPageStep(0);
  }, [formPageStore]);

  return (
    <section className="createFormPage pt-5 pb-5">
      <div className="container pb-5">
        <h2 className="my-h2">Заполните заявку на поверку приборов</h2>
        {formPageStore.getIsAlertShow && (
          <div className="alert-wrapper">
            <Alert
              className="fs-15 my-alert"
              variant={formPageStore.getAlertTypeColor}
              onClose={() => closeAlert()}
              dismissible={formPageStore.getAlertTypeColor !== ALERT_COLORS.YELLOW}
            >
              <Alert.Heading className="my-h3">Внимание!</Alert.Heading>
              <p>{formPageStore.getAlertMsg}</p>
            </Alert>
          </div>
        )}

        <Form className="signUpToCheck__form fs-15" onSubmit={event => nextStepClick(event)}>
          <div className="row">
            {formPageStore.getCreateFormPageStep === CHECK.ORGANIZATION_CHECK && <OrganizationForm />}
            {formPageStore.getCreateFormPageStep === CHECK.DEVICE_LIST_CHECK && <DevicesForm />}
          </div>
          <Button
            className="form__btn mr-3 mt-4"
            variant="primary"
            disabled={formPageStore.getCreateFormPageStep === CHECK.ORGANIZATION_CHECK ? true : false}
            onClick={backStepClick}
          >
            Назад
          </Button>
          <Button className="form__btn mt-4" type="submit" variant="primary">
            {formPageStore.getCreateFormPageStep === CHECK.ORGANIZATION_CHECK ? 'Продолжить' : 'Отправить заявку'}
          </Button>
        </Form>
        <GoogleReCaptcha action={formPageStore.getApplicationFormData} />
      </div>
    </section>
  );
});
