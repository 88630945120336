import React from 'react';

import './contactsPage.scss';

export const ContactsPage = () => {
  return (
    <section className="contactsPage pt-5 pb-5">
      <div className="contactsPage-wrapper pb-5 container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h2 className="my-h2">Контакты</h2>
            <div className="contactsPage-Text fs-15">
              <div className="pb-5">
                <h3 className="pb-4">ООО &quot;ТТ-Энерго&quot;</h3>
                <p>
                  <b>Адрес:</b> 426053, Удмуртская Республика, г. Ижевск, ул. Салютовская, 5а
                </p>
                <p>
                  <b>Телефон:</b> +7 (3412) 912426
                </p>
                <p>
                  <b>Электронный адрес:</b> info@tt-en.ru
                </p>
                <p>
                  <b>Сайт:</b> http://tt-en.ru
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <h2 className="my-h2">Схема проезда</h2>
            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3Af165847a25f8fb002cda1b65df8ebaea9c6893c95c671a1e0c7da8404eb47df7&amp;source=constructor"
              width="100%"
              height="430"
              frameBorder="0"
            >
              <p>Ваш браузер устарел для отображения карты</p>
            </iframe>
          </div>
        </div>
      </div>
    </section>
  );
};
